body {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
  text-decoration: none;
  
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", sans-serif;
}
