.service-page {
    &__detailed-wrapper {
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 5% 15%;

        @media only screen and (max-width: 767px) {
            padding: 5% 8%;
            display: block;

          }
    }

    &__detailed {
        width: 100%;
        display: flex;
        justify-content: center;

        @media only screen and (max-width: 767px) {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 50px;
          }
    }

    &__title {
        width: fit-content;
        text-align: left;
        font-size: 18px;

    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    &__model { 
        font-size: 36px;
        font-weight: 600;
        @media only screen and (max-width: 767px) {
            font-size: 18px;
          }
    }

    &__detailed-item {
        width: 500px;
        height: 500px;
        background-size: contain;
        background-repeat: no-repeat;

        @media only screen and (max-width: 767px) {
            width: 300px;
            height: 300px;
          }
    }

    &__description {
        margin: 50px 0 0 50px;
        
        @media only screen and (max-width: 767px) {
            margin: 0;
          }

    }
    &__img-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        margin-top: 25px;

        @media only screen and (max-width: 767px) {
            display: block;
          }
    }

    &__img {
        width: 450px;
        height: 270px;
        border-radius: 5px;

        @media only screen and (max-width: 767px) {
            width: 350px;
            height: 250px;
          }
    }
}