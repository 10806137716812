.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #283033 ;
    color: rgb(77, 77, 77)r;
    padding: 20px 20px 50px;
    justify-content: space-around;
    align-items: center;

    @media only screen and (max-width: 767px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
      }

    &__about {
        width: 300px;
        height: 250px;
        display: flex;
        flex-direction: column;
        color:#f6f6f6;
        gap: 3px;
    }

    &__title {
        
        font-size: 22px;
        color: #f6f6f6;

        @media only screen and (max-width: 767px) {
            text-align: center;
          }
    }

    &__logo-block {
        width: 270px;
        height: 200px;

        @media only screen and (max-width: 767px) {
            height: 185px;
          }
    }

    &__logo-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      color: #f6f6f6;
      font-weight: 500;
    }

    &__logo{
        width: 100%;
        height: 100%;
        filter: invert(1) !important;
    }

    &__block{
        width: 200px;
        height: 250px;
        color:#f6f6f6;

        
        @media only screen and (max-width: 767px) {
          width: 250px;
        }  
    }

    &__social-wrapper{
        width: 110px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px 10px;
        margin-top: 25px;
        color:#f6f6f6;

        @media only screen and (max-width: 767px) {
            flex-wrap: nowrap;
            gap: 20px;
          }
    }

    &__icon{
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    &__address {
        display: flex;
        padding-bottom: 5px;
    }

    &__label {
        padding-left: 5px;
        color: #f6f6f6 !important;
        text-decoration: none;
    }

    &__navigation {
        height: 250px;

        @media only screen and (max-width: 767px) {
            text-align: center;
          }
    }

    &__nav-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 12px;
    }

    &__nav-item {
        color:#f6f6f6;
        margin-right: 12px;
        font-weight: 600;
        text-decoration: none;
    }

    &__icon {
        width: 35px;
        height: 35px;
        cursor: pointer;
        background-size: cover;
      }

    &__icon1 {
        background-image: url('../../../../pictures/icons/facebook.png');
      }
  
      &__icon2 {
        background-image: url('../../../../pictures/icons/whatsapp.png');
        margin-right: 15px;

        @media only screen and (max-width: 767px) {
            margin: 0;
          }
      }
  
      &__icon3 {
        background-image: url('../../../../pictures/icons/telegram.png');
      }
  
      &__icon4 {
        background-image: url('../../../../pictures/icons/instagram.png');

        margin-right: 15px;

        @media only screen and (max-width: 767px) {
            margin: 0;
          }
      }

      &__icon5 {
        background-image: url('../../../../pictures/icons/trip.png');

        margin-right: 15px;
        border-radius: 50%;

        @media only screen and (max-width: 767px) {
            margin: 0;
          }
      }
}