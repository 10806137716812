.app {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    &__icon {
        position: fixed;
        width: 50px;
        height: 50px;
        bottom: 40px;
        background-image: url('./pictures/whatsapp_icon.png');
        background-size: cover;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 3px 4px 40px #999;
        right: 15px;
        z-index: 100;
        cursor: pointer;
        transition: .5s;

        
        &:hover {
            transform: scale(1.1);
            box-shadow: 0 0 15px #10ff14;
            transform: rotate(360deg);
        }
    }
}