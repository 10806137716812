.slideshow {
  
  &__wrapper {
    background: #f6f6f6;
    position: relative;
  }

  &__text {
    width: 500px;
    position: absolute;
    background-color: transparent;
    z-index: 999;
    font-size: 42px;
    color: #f6f6f6;
    text-transform: uppercase;
    top: 30%;
    right: 5%;
    font-weight: 600;

    
    @media only screen and (max-width: 767px) {
      font-size: 12px;
      width: 100px;
    }
  }

  &__text2 {
    font-size: 32px;

    @media only screen and (max-width: 767px) {
      font-size: 8px;
    }
  }

  &__each-slide {
    display: flex;
    width: 100%;
    height: 100vh;

    @media only screen and (max-width: 767px) {
      height: 200px;
    }
  
    > div {
      width: 100%;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .progressive-image {
    filter: blur(20px);
    transition: filter 0.3s;
  }

  .progressive-image.loaded {
    filter: blur(0);
  }
}