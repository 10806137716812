.tours {
    padding: 2% 15% 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    background: #f6f6f6;

    &__header-title {
        color: #283033 ;
        margin-bottom: 25px;
        font-size: 50px;
        text-transform: uppercase;
        font-weight: 500;
        border-bottom: 1px solid #c8c8c8;

        @media only screen and (max-width: 767px) {
            font-size: 35px;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 50px;
    }

    &__tours {
        display: flex;
        justify-content: space-between;
        gap: 50px;

        @media only screen and (max-width: 767px) {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 50px;
        }
    }

    &__tours2 {
        display: flex;
        margin: 0 8%;
        justify-content: space-between;
        gap: 50px;

        @media only screen and (max-width: 767px) {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 50px;
            margin: 0;
        }
    }

    &__item {
        width: 35%;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        border: 1px solid #c8c8c8;
        transition: all 0.4s ease 0s;

        &:hover {
            box-shadow: 0 0 30px rgba(128, 128, 128, 0.5);
        }

        @media only screen and (max-width: 767px) {
            width: 100%;
            display: block;
        }
    }

    &__picture{
        width: 100%;
        height: 200px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-size: cover;
    }

    &__img1 {
        background-image: url('https://i.ibb.co/TtFPv2y/reama-riders-700x400-2.jpg');
    }

    &__img2 {
        background-image: url('https://i.ibb.co/vmzkKd8/reama-riders-700x400-7.jpg');
    }
    
    &__img3 {
        background-image: url('https://i.ibb.co/McBnQM0/reama-riders-700x400.jpg');
    }

    &__img4 {
        background-image: url('https://i.ibb.co/7VKRF6r/reama-riders-700x400-4.jpg');
    }

    &__img5 {
        background-image: url('https://i.ibb.co/N6MXKgZ/reama-riders-700x400-6.jpg');
    }

    &__title {
        font-size: 22px;
        font-weight: 700;
        text-align: center;
        margin: 20px 5px 0;
    }

    &__button {
        width: 100px;
        margin-bottom: 15px !important;
    }

    &__description {
        font-size: 16px;
        font-weight: 500;
        margin: 0 25px 10px;
    }

    &__footer-title {
        color: #283033 ;
        margin: 100px 0 12px;
        font-size: 50px;
        text-transform: uppercase;
        font-weight: 500;
        border-top: 1px solid #c8c8c8;

        @media only screen and (max-width: 767px) {
            font-size: 25px;
        }
    }

    &__footer-desc {
        font-size: 18px;
        font-weight: 500;
        margin: 25px 25px;
    }

    &__general-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px 20%;
        @media only screen and (max-width: 767px) {
            padding: 25px 0;

        }
    }

    &__general-block {
        font-size: 16px;
        font-weight: 500;
        border-radius: 5px;
        box-shadow: 5px 0px 55px 0px #999999;
        padding: 15px;
        margin: 0 25px;

        @media only screen and (max-width: 767px) {
            margin: 0;
        }
    }

    &__icon {
        font-size: 62px !important;
        color: #283033 ;
    }
}