.tour66 {
    padding: 50px 12%;

    @media only screen and (max-width: 767px) {
        display: flex;
        justify-content: center;
        padding: 35px 10%;

      }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        gap: 50px;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
            gap: 25px;

          }
    }

    &__img {
        height: 300px;
        border-radius: 5px;

        @media only screen and (max-width: 767px) {
            height: 150px;
          }
    }

    &__text-wrapper {
        width: 50%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        @media only screen and (max-width: 767px) {
            width: 100%;
            gap: 25px;
          }
    }

    &__text {
        font-size: 18px;
        @media only screen and (max-width: 767px) {
            padding: 0 15px;
            text-align: center;
          }
    }

    &__button {
        padding: 25px 0 50px;
    }

    &__video-wrapper {
        @media only screen and (max-width: 767px) {
            display: none;
          }
    }

    &__mobile-wrapper {
        display: none;
        @media only screen and (max-width: 767px) {
            display: flex;
            justify-content: center;
          }
    }
    
}