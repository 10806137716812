.rent {

    &__img1{
        background-image: url('../../pictures/rent-motos/BMW1200GS.webp');
    }
    &__img2{
        background-image: url('../../pictures/rent-motos/BMW\ F650GS.jpg');
    }
    &__img3{
        background-image: url('../../pictures/rent-motos/DR650SEM.jpeg');
    }
    &__img4{
        background-image: url('../../pictures/rent-motos/Honda\ Africa\ Twin.jpeg');
    }
    &__img5{
        background-image: url('../../pictures/rent-motos/Kawasaki\ KLE\ 500.jpg');
    }
    &__img6{
        background-image: url('../../pictures/rent-motos/Kawasaki-KLR650.jpeg');
    }
    &__img7{
        background-image: url('../../pictures/rent-motos/Yamaha\ XT660R.jpg');
    }

    &__motos-wrapper{
        display: flex;
        justify-content: space-between;
        margin: 25px 50px;

        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
            margin: 0;
          }
    }

    &__moto-item {
        width: 200px;
        height: 150px;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        transition: all 0.4s ease 0s;

        position: relative;
	    overflow: hidden;

	&::before {
		background: linear-gradient(
			to right,
			fade_out(#fff, 1) 0%,
			fade_out(#fff, 0.7) 100%
		);
		content: "";
		display: block;
		height: 100%;
		left: -75%;
		position: absolute;
		top: 0;
		transform: skewX(-25deg);
		width: 50%;
		z-index: 2;
	}

	&:hover,
	&:focus {
		&::before {
			animation: shine 0.85s;
		}
	}

	@keyframes shine {
		100% {
			left: 125%;
		}
	}


        @media only screen and (max-width: 767px) {
            margin: 15px;
            width: 150px;
            height: 150px;
            }
    }

    &__detailed-wrapper {
        display: flex;
        padding: 5% 15%;

        @media only screen and (max-width: 767px) {
            padding: 5% 8%;
          }
    }

    &__detailed {
        width: 100%;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #999999;

        @media only screen and (max-width: 767px) {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 50px;
          }
    }

    &__title {
        width: fit-content;
        text-align: center;

    }

    &__model { 
        font-size: 36px;
        font-weight: 600;
        @media only screen and (max-width: 767px) {
            font-size: 18px;
          }
    }

    &__detailed-item {
        width: 500px;
        height: 500px;
        background-size: contain;
        background-repeat: no-repeat;

        @media only screen and (max-width: 767px) {
            width: 300px;
            height: 300px;
          }
    }

    &__description {
        margin: 50px 0 0 50px;
        
        @media only screen and (max-width: 767px) {
            margin: 0;
          }

    }

    &__values {
        font-size: 18px;
        display: flex;
        margin: 5px 0 0 15px;

        @media only screen and (max-width: 767px) {
            margin: 0;
            font-size: 18px;
          }

    }

    &__key {
        font-weight: 600;
        margin-right: 5px;
    }

    &__general {
        align-items: center;
        text-align: center;
    }

    &__general-title {
        font-size: 50px;
        @media only screen and (max-width: 767px) {
            font-size: 36px;
        }
    }

    &__general-blocks {
        display: flex;
        justify-content: space-between;
        padding: 5% 15%;

        @media only screen and (max-width: 767px) {
            display: flex;
            justify-content: space-between;      
            flex-direction: column;
            gap: 25px;
        }
    }

    &__general-block {
        min-width: 300px;
        font-size: 16px;
        font-weight: 500;
        border-radius: 5px;
        box-shadow: 5px 0px 55px 0px #999999;
        padding: 15px;
        margin: 0 25px;

        @media only screen and (max-width: 767px) {
            margin: 0;
            min-width: 100%;
        }
    }

    &__icon {
        font-size: 42px !important;
        color: #283033 ;
    }

    &__contact {

        margin: 15px 0 0 15px;

        @media only screen and (max-width: 767px) {
            display: flex;
            justify-content: center;
        }
    }

    &__texts {
        text-align: left;
    }
}