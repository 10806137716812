.gallery {
    width: 680px;
    padding: 0 50px;

    &__wrapper {
     min-height: 400px;
     display: flex;
     justify-content: center;
     align-items: center;
     text-align: center;

     @media only screen and (max-width: 767px) {
      min-height: 180px;
    }
    }

    &__gallery-image {
      width: 680px;
      height: 400px;

      @media only screen and (max-width: 767px) {
        width: 330px;
        height: 180px;
      }
    }

    @media only screen and (max-width: 767px) {
        width: 330px;
        padding: 0;
      }


}