.header {
    width: 100%;
    height: 55px;
    box-sizing: border-box;
    padding: 50px 80px;
    color: #fff; 
    display: flex;
    justify-content: space-between;
    align-items: center;



    &__home {
      position: absolute;
      z-index: 9999;
      background: transparent;
    }

    @media only screen and (max-width: 767px) {
      padding: 5px 8px;
      background-color: #283033;
      position: relative;
    }

  &__logo {
    width: 90px;
    height: 65px;
    padding: 3px 5px;
    margin-right: 100px;
    background: url('../../pictures/logo_black1.png') no-repeat;
    background-size: cover;
    filter: invert(1);

    @media only screen and (max-width: 767px) {
      width: 30px;
      height: 25px;
      margin-right: 0;
    
    }
  }

  &__burger {
    display: none;
    
    @media only screen and (max-width: 767px) {
      display: block;
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    gap: 32px;
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

    &__menu {
      list-style: none;
      display: flex;
      gap: 1rem;

    }

    &__nav-item {
        color:#f6f6f6;
        margin-right: 12px;
        font-weight: 600;
        text-decoration: none;
        
        &--active {
            color:#f6f6f6;
            margin-right: 12px;
            text-decoration: underline;
            font-weight: 600;
        }
    }
    

    &__languages {
        display: flex !important;
        justify-content: space-between;

      a {
        color:#f6f6f6;
        text-decoration: none;
      }

      @media only screen and (max-width: 767px) {
        padding-right: 20%;
      }
    }

    &__left-languages {
      margin-left: 50px;
      @media only screen and (max-width: 767px) {
          display: none !important;
        }
  }
    
    &__link-wrapper{
      padding: 0 3px;
    }

    .bm-overlay{
      width: 0% !important;
    }

    .bm-menu-wrap {
      position: fixed;
      height: 100%;
    }
    
    .bm-menu {
      background: rgba(0, 0, 0, 0.7); 
      padding: 2.5em 1.5em 0;
      font-size: 1.15em;
      text-decoration: none;
      margin-top: 22px;
    }

    .sticky {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7); /* Black with 70% transparency */
      transition: transform 0.3s ease-in-out;
      z-index: 1000;
    }
    
    .visible {
      transform: translateY(0);
    }
    
    .hidden {
      transform: translateY(-100%);
    }

    &__sticky-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 50px;
      @media only screen and (max-width: 767px) {
        padding: 10px 5px;

      }
    }

    &__sticky-logo {
      color: white;
      text-decoration: none;
    }

    &__sticky-languages {
      margin-right: 15px;

      @media only screen and (max-width: 767px) {
        display: none !important;
      }
    }

    &__icons {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      @media only screen and (max-width: 767px) {
        padding-right: 12%;
      }
    }

    &__icons-sticky {
      @media only screen and (max-width: 767px) {
        padding: 0 !important;
      }
    }

    &__icon {
      width: 25px;
      height: 25px;
      cursor: pointer;
      background-size: cover;
    }

    &__icon1 {
      background-image: url('../../pictures/icons/facebook.png');
    }

    &__icon2 {
      background-image: url('../../pictures/icons/whatsapp.png');
    }

    &__icon3 {
      background-image: url('../../pictures/icons/telegram.png');
    }

    &__icon4 {
      background-image: url('../../pictures/icons/instagram.png');
    }

    &__flag {
      cursor: pointer;
      width: 30px;
      height: 12px;
    }
  }