.tour {
    padding: 50px 10% ;
    background-color: #f6f6f6;

    &__header {
        width: 100%;
        height: 100px;
        background-color: #283033;
        @media only screen and (max-width: 767px) {
            display: none;
          }

    }

    &__wrapper {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 767px) {
            display: block;
          }
    }

    &__title1 {
        font-size: 32px;
        font-weight: 600;
        @media only screen and (min-width: 767px) {
            display: none;
          }

    }

    &__title {
        font-size: 32px;
        font-weight: 600;
        @media only screen and (max-width: 767px) {
            display: none;
          }
    }

    &__desc-wrapper {
        padding: 8px 0;
    }

    &__desc {
        font-size: 16px;
    }

    &__about {
        margin: 25px 0;
    }

    &__day-wrapper {
        display: flex;
        gap: 10px;
        margin: 15px 0;
    }

    &__day-number {
        min-width: fit-content;
        font-weight: 600;
    }

    &__slide {
        width: 50vw;
    }

    &__gallery-wrapper {
        display: flex;

        @media only screen and (max-width: 767px) {
            display: flex;
            flex-direction: column-reverse;
          }
    }

    &__map-wrapper {
        width: 100%;
        padding: 25px 50px;
        @media only screen and (max-width: 767px) {
            padding: 25px 0;

          }
    }

    &__map {
        width: 680px;
        @media only screen and (max-width: 767px) {
            width: 330px;

          }
    }
}